<template>
  <div id="app">
    <div v-if="!isLoaded">
      <b-loading v-model="isLoading" :can-cancel="true"></b-loading>

    </div>
    <div v-show="isLoaded">
      
    <Header v-if="!$auth.isLoading && isLoggedIn"/>
    
    <PageLayout v-if="!$auth.isLoading && isLoggedIn" :userId="getAccountId" @profileSet="isLoaded = true"/>
    
    
<footer class="footer">
  <div class="content has-text-centered">
    <p>
       <a href="https://www.cartogro.com" target="_blank">
      Cartogro
      </a><br/>
      &copy; 2021-2022 | Forstek Ltd | All Rights Reserved
      <br/>
      <a href="https://www.cartogro.com/privacy-policy"  target="_blank">Privacy Policy</a> | <a  target="_blank" href="https://www.cartogro.com/terms">Terms</a>
    </p>
  </div>
</footer>
    </div>

  </div>


</template>


<script>

import Header from './components/header.vue';
import PageLayout from './views/PageLayout.vue';
import { getInstance } from "./auth/index";
//import { GetWithAuth } from './services/apiClient';
import { mapGetters } from 'vuex';

 export default {
   name: 'App',
    mounted(){
      this.authService = getInstance();
      if (this.authService.isAuthenticated) {
        this.accountId = this.$auth.user.sub;        
      } 

    },
    data(){
      return{
        accountId: null,
        isLoaded: false,
        isLoading: true
      }
    },
    computed: {    
      isLoggedIn: function(){
        const authService = getInstance();
        if(authService.isAuthenticated && this.$auth.user.sub){
          return true;
        }
        return false;
      },
      getAccountId: function(){
        if(!this.accountId){
          const authService = getInstance();
          if (authService.isAuthenticated) {
            return this.$auth.user.sub;        
          } 
         // alert("broke");
        }
        return this.accountId;
      }
    },
    methods:{
    //  ...mapMutations(['setProfile']),
    
      ...mapGetters(['getProfile']),
    },
   components: {
     PageLayout,
     Header
   }
 }

</script>

<style lang="scss">

.is-brand-color{
  background-color: #0B3954 !important;
}

#app {
}

#nav {
}

.footer{
  border-top:1px solid #0B3954;
}

.breadcrumb{
    @media only screen and (max-width: 480px) {
      margin-bottom: 5px !important;
      margin-left: 10px;
    }
}
</style>
