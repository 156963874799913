<template>
<b-sidebar
                v-show="!$isMobile()"
                position="static"
                :expand-on-hover="false"
                :reduce="false"
                type="is-light"
                open
            >
    <b-menu :activable='false' :accordion='true'>
    <b-menu-list label="Menu">
      <b-menu-item label="Home" icon="house-user" tag="router-link" to="/home"></b-menu-item>
      <b-menu-item label="Payment Info" icon="money-bill-wave" tag="router-link" to="/payment-info"></b-menu-item>
      <!--<b-menu-item label="Settings" icon="cog" tag="router-link" to="/wall"></b-menu-item>
      <b-menu-item label="Profile" icon="user" tag="router-link" to="/profile"></b-menu-item>-->
    </b-menu-list>
    <b-menu-list label="Widgets">
      <b-menu-item icon="plus" label="Create new"  tag="router-link" to="/maps/new"></b-menu-item>
      <b-menu-item icon="map" expanded>
        <template #label="props">
          Maps
          <b-icon class="is-pulled-right" :icon="expandedIconClass(props)"></b-icon>
        </template>
        <b-menu-item v-for="map in getMapConfigs()" :key="map.id"  :label="map.name"  tag="router-link" :to="`/map/${map.id}`"></b-menu-item>        
      </b-menu-item>
    </b-menu-list>
    <b-menu-list label="Insights">
      <b-menu-item label="Bookings" icon="list" tag="router-link" to="/bookings"></b-menu-item>
      <!--<b-menu-item label="Summary" icon="chart-pie" tag="router-link" to="/wall"></b-menu-item>
      <b-menu-item label="Analytics" icon="chart-line" tag="router-link" to="/wall"></b-menu-item>-->
      <b-menu-item label="Payments" icon="money-bill-wave" tag="router-link" to="/payouts"></b-menu-item>
    </b-menu-list>
    <b-menu-list label="Get in Touch">
      <b-menu-item label="Support" icon="life-ring" tag="router-link" to="/support"></b-menu-item>
      <b-menu-item label="Message Us" icon="comments" tag="router-link" to="/contact"></b-menu-item>
    </b-menu-list>
  </b-menu>
</b-sidebar>
    
</template>

<script>
 import { mapGetters } from 'vuex';
 export default {
  name: 'SideBar',
  data(){
      return{
          //mapsExpanded: true
      }
  },
  methods: {
      ...mapGetters(['getMapConfigs']),
      expandedIconClass(props){
          const result = props.expanded ? 'chevron-up' : 'chevron-down';
          return result;
      }
  }
  }
 </script>

 
<style >
  .menu-list a.is-active{
    background-color: #0B3954 !important;
  }
</style>