import Vue from 'vue'
import Vuex from 'vuex'
import { GetWithAuth } from '../services/apiClient';

Vue.use(Vuex)
//B2C_1_cartogro_editprofile
export default new Vuex.Store({
  state: {
    msalConfig: {
      auth: {
        clientId: '6f21a4ec-1308-4479-b829-628b3e046b6e',

        authority:
          'https://cartogro.b2clogin.com/cartogro.onmicrosoft.com/B2C_1_cartogro',
        knownAuthorities: ["cartogro.onmicrosoft.com", "cartogro.b2clogin.com"],
        redirectUri: "http://localhost:8080/console",
        //scopes: ["openid", "profile","https://cartogro.onmicrosoft.com/users-api/users.read"],
        scopes: ["https://cartogro.onmicrosoft.com/users-api/users.read"],
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      },
    },
    accessToken: 'empty',
    user: null,
    profile: null,
    editMapConfig: { 
      id: null,
      widgetHeight: 400,
      mapStyle: 'Standard',
      filters:{
        categoryGroupIds: []
      },
      lat: 15,
      lng: 12,
      zoom: 2,
      name: "",
        pois: {},
        pins: [],
        route: [],  
        lineRoute: [],
        routeType: null,
        routeColor: '#1122AA',
        routeLineWeight: 5,
        area: {
          outlineColor: '#1122AA',
          outlineWeight: 5,
          fillColor: '#1122AA',
          fillOpacity: 0.5,
          geoJsonCoordinates: null
        }
      } 
  },
  getters:{
    getAccessToken(state){
      return state.accessToken;
    },
    getMapConfigs(state){
      return state.profile?.mapConfigs;
    },
    getProfileId(state){
      return state.profile?.profile.id;
    },
    getProfile(state){
      return state.profile;
    },
    getUser(state){
      return state.user;
    },
    getMapConfig(state){
      return state.editMapConfig;
    },
  },
  actions:{
    loadMapConfig(context, data) {
      return new Promise((resolve, reject) => {
        const config = context.getters.getMapConfig;
          if (config.id && config.id == data){
            
            resolve(config);
          }
          else{
           // GetWithAuth("https://localhost:44369/api/mapconfigs/" + data)
            GetWithAuth("https://api.cartogro.com/api/mapconfigs/" + data)
              .then(response => {
                context.commit('setEditMapConfig', response);
                resolve(response);
              })
              .catch(error => {
                reject(error);
              })     
          }
      })
    }
  },
  mutations: {
    setRouteType(state, value){
      state.editMapConfig.routeType = value;
    },
    resetMapConfig(state){
      state.editMapConfig = { 
        id: null,
        widgetHeight: 600,
        mapStyle: 'Standard',
        filters:{
          categoryGroupIds: []
        },
        lat: 15,
        lng: 12,
        zoom: 2,
        name: "",
          pois: {},
          pins: [],
          route: [],  
          lineRoute: [],
          routeType: null,
          routeColor: '#1122AA',
          routeLineWeight: 5,
          area: {
            outlineColor: '#1122AA',
            outlineWeight: 5,
            fillColor: '#1122AA',
            fillOpacity: 0.5,
            geoJsonCoordinates: null
          }
        } 
    },
    setAccessToken(state, token){
      state.accessToken = token;
    },
    setUser(state, user){
      state.user = user;
    },
    setProfile(state, profile){
      if(profile?.isNewUser){
        console.log("user init");
        window.gtag('event', 'conversion', {'send_to': 'AW-1051536346/jYCRCJS2h8IDENrXtPUD'});
      }
      state.profile = profile;
    },
    setEditMapConfig(state, mapConfig){
      state.editMapConfig = mapConfig;
    },

    updateZoom(state, data){
      state.editMapConfig.zoom = data;
    },
    updatePosition(state, data){
      state.editMapConfig.lat = data.lat;
      state.editMapConfig.lng = data.lng;
    },
    updateWidgetHeight(state, data){
      state.editMapConfig.widgetHeight = data;
    },
    updateMapStyle(state, data){
      state.editMapConfig.mapStyle = data;
    },
    updateWidgetName(state, data){
      state.editMapConfig.name = data;
    },

    setPin(state, pin){
      let existingPin = state.editMapConfig.pins.find(x=>x.id == pin.id);
      if(existingPin == pin){
        existingPin = pin;
      }
      else{
        state.editMapConfig.pins.push(pin);
      }
    },    
    deletePin(state, pinId){
      state.editMapConfig.pins = state.editMapConfig.pins.filter(x => x.id != pinId);
    },

    deleteArea(state){
      state.editMapConfig.area.geoJsonCoordinates = null;
    },
    addArea(state, data){
      state.editMapConfig.area.geoJsonCoordinates = data;
    },
    updateAreaOutlineColor(state, data){
      state.editMapConfig.area.outlineColor = data;
    },
    updateAreaOutlineWeight(state, data){
      state.editMapConfig.area.outlineWeight = data;
    },
    updateAreaFillColor(state, data){
      state.editMapConfig.area.fillColor = data;
    },
    updateAreaFillOpacity(state, data){
      state.editMapConfig.area.fillOpacity = data;
    },
    
    deleteRoute(state){
      state.editMapConfig.route = [];
      state.editMapConfig.lineRoute = [];
      state.editMapConfig.routeType = null;
    },
    addRouteLeg(state, routeLeg){
      state.editMapConfig.route.push(routeLeg);
    },
    addRouteLineLeg(state, routeLeg){
      state.editMapConfig.lineRoute.push(routeLeg);
    },
    updateRouteType(state, data){
      state.editMapConfig.routeType = data;
    },
    updateRouteColor(state, data){
      state.editMapConfig.routeColor = data;
    },
    updateRouteLineWeight(state, data){
      state.editMapConfig.routeLineWeight = data;
    },

    //updateMapLocation(state, data){
    //  state.editMapConfig.lat = data.lat;
    //  state.editMapConfig.lng = data.lng;
    //  state.editMapConfig.zoom = data.zoom;
    //},


    //updateRouteColor(state, data){
    //  state.editMapConfig.routeColor = data;
    //},
    //updateRouteLineWeight(state, data){
    //  state.editMapConfig.routeLineWeight = data;
    //},
    //updateOutlineColor(state, data){
    //  state.editMapConfig.area.outlineColor = data;
    //},
    //updateOutlineWeight(state, data){
    //  state.editMapConfig.outlineWeight = data;
    //},
    //updateFillColor(state, data){
    //  state.editMapConfig.area.fillColor = data;
    //},
    //updateFillOpacity(state, data){
    //  state.editMapConfig.area.fillOpacity = data;
    //},

    setDefaultAuthority(state){
      state.msalConfig.auth.authority = 'https://cartogro.b2clogin.com/cartogro.onmicrosoft.com/B2C_1_cartogro';
    },
    setEditProfileAuthority(state){
      state.msalConfig.auth.authority = 'https://cartogro.b2clogin.com/cartogro.onmicrosoft.com/B2C_1_cartogro_editprofile';
    },
  },
  //actions: {
  //},
  modules: {
  }
})
