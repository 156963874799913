import * as Cookies from 'js-cookie';
import store from '../auth/index';
import { getters } from 'vuex';
import { getInstance } from "../auth/index";
import axios from "axios";

const getTokenFromCookie = () => {
    return Cookies.get('auth')
}

const getRequestHeadersWithAuth = (accessToken) => {
    return {
        Authorization: `Bearer ${accessToken}`,
       'Content-Type': 'application/json; charset=UTF-8'
   } 
    
}

const getToken = async () => {
    const authService = getInstance();
    return await authService.getTokenSilently();// this.$auth.getTokenSilently();
}

export const GetWithAuth = async (path) =>{
    const token = await getToken();
    const { data } = await axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`    // send the access token through the 'Authorization' header
        }
      })
      .catch(()=> { });
    return data;
    //return await fetch(path, {
    //    method: 'GET',
    //    headers: getRequestHeadersWithAuth(token)
//,   //     })
}


export const PostWithAuth = async (path, payload) =>{
    const token = await getToken();
    const { data } = await axios.post(path, payload, {
        headers: {
          Authorization: `Bearer ${token}`,    // send the access token through the 'Authorization' header
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
      .catch(()=> { });
    return data;
    //return await fetch(path, {
    //    method: 'POST',
    //    body: JSON.stringify(data),
    //    headers: getRequestHeadersWithAuth(token)
   //     })
}

export const TrackEvent = async (userId, message) =>{
  console.log(message);
  GetWithAuth("https://api.cartogro.com/api/track/" + userId.replace('auth0|','') + "/event?description=" + message);
    
}