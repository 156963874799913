import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'
import store from '../store'
import * as Cookies from 'js-cookie';
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/maps',
    name: 'Maps',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/maps/new',
    name: 'CreateMap',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/EditMap.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/map/:mapId',
    name: 'MapSummary',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewMap.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/map/:mapId/edit',
    name: 'EditMap',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/EditMap.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/home',
    name: 'Home',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Summary.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/profile',
    name: 'Profile',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/bookings',
    name: 'Bookings',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Bookings.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/payouts',
    name: 'PayoutSlips',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/PayoutSlips.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/support',
    name: 'Support',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/payment-info',
    name: 'PaymentInfo',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/PaymentInfo.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/',
    redirect: '/home'
  }
]

const router = new VueRouter({
  routes,
  linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
    
 // if (to.name == 'Wall') {    
 //   if (!router.app.$store.state.user) {
 //     next()
 //   }
 //   else{
 //     next({ name: 'Console' });
 //   }
 // }
 // else if(!to.name){    
 //   if (router.app.$store.state.user) {
 //     next({ name: 'Console' })
 //   }
 //   else{
 //     next({ name: 'Wall' });
 //   }
 // }
  //else if (!router.app.$store.state.user) {
  //  next({ name: 'Wall' })
 // }
 // else next()
 next();
})

export default router
