import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Emitter from 'tiny-emitter';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '../node_modules/@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '../node_modules/@fortawesome/vue-fontawesome'
import VueMobileDetection from "vue-mobile-detection";
import VueClipboard from 'vue-clipboard2';
import AsyncComputed from 'vue-async-computed'

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import Auth0Plugin from "./auth/index.js";

require('@/assets/main.scss');

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.use(Buefy, { defaultIconPack: 'fas',  defaultIconComponent: FontAwesomeIcon });
Vue.use(VueMobileDetection);
Vue.use(VueClipboard);
Vue.use(AsyncComputed);

Vue.config.productionTip = false;
Vue.prototype.$msalInstance = {};
Vue.prototype.$emitter = new Emitter();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
