<template>
<b-navbar type="is-white"  :transparent="true"  :shadow="false" style= "border-bottom: 2px solid #0B3954">
        <template #brand>
            <b-navbar-item>
                <img style="width: 150px"
                    src="../assets/FullLogo.svg"
                    alt="Cartogro.com"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item class="desktop-viewport-nav" tag="router-link" :to="{ path: '/' }">
                Portal
            </b-navbar-item>
            <b-navbar-item class="mobile-viewport-nav" tag="router-link" :to="{ path: '/' }">
                Home
            </b-navbar-item>
            <b-navbar-dropdown class="mobile-viewport-nav" label="Map Widgets">
                  <b-navbar-item tag="router-link" :to="{ path: '/maps/new' }">
                    <strong>Create New Map</strong>
                </b-navbar-item>
                <b-navbar-item v-for="map in getMapConfigs()" :key="map.id" tag="router-link" :to="`/map/${map.id}`">{{map.name}}</b-navbar-item>        
            </b-navbar-dropdown>
            <b-navbar-item class="mobile-viewport-nav" tag="router-link" :to="{ path: '/bookings' }">
                Bookings
            </b-navbar-item>
            <b-navbar-item class="mobile-viewport-nav" tag="router-link" :to="{ path: '/payouts' }">
                Payments
            </b-navbar-item>
            <b-navbar-item class="mobile-viewport-nav" tag="router-link" :to="{ path: '/payment-info' }">
                Payment Info
            </b-navbar-item>
            <b-navbar-item class="mobile-viewport-nav" tag="router-link" :to="{ path: '/support' }">
                Support
            </b-navbar-item>
            <b-navbar-item tag="router-link" :transparent="true" :to="{ path: '/contact' }">
                Contact Us
            </b-navbar-item>
            <b-navbar-item href="https://www.cartogro.com/documentation" :transparent="true" tag="a" target="_blank">
                Documentation
            </b-navbar-item>
            <!--<b-navbar-dropdown label="Documentation">
                <b-navbar-item href="https://www.cartogro.com/documentation#">
                    Getting Started
                </b-navbar-item>
                <b-navbar-item href="#">
                    Tutorials
                </b-navbar-item>
                <b-navbar-item href="#">
                    Examples
                </b-navbar-item>
                <b-navbar-item href="#">
                    FAQ
                </b-navbar-item>
            </b-navbar-dropdown>-->
            
        </template>

        <template #end>
            <b-navbar-item tag="div" class="desktop-viewport-nav">
                <div>
                        <h2>Logged in {{ $auth.user.nickname }}</h2>
                 </div>
            </b-navbar-item>
            <b-navbar-item tag="div">
                <div class="buttons">
                <!--<div v-if="!$auth.loading">-->
                    <!-- show login when not authenticated -->
                    <b-button v-if="!isAuthenticated()" @click="login">Log in</b-button>
                    <!-- show logout when authenticated -->
                    <b-button v-if="isAuthenticated()" @click="logout">Log out</b-button>
                <!--</div>-->
                
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>


<script>
 import { mapGetters } from 'vuex';
export default {
  name: 'Header',
  props: {
  },
  methods: {
      ...mapGetters(['getMapConfigs']),
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    isAuthenticated(){
        return this.$auth.isAuthenticated;
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .mobile-viewport-nav{
        @media only screen and (min-width: 961px) {
            display:none !important;
        }
    }
    .desktop-viewport-nav{
        @media only screen and (max-width: 960px) {
            display:none !important;
        }
    }
</style>
